<template>
    <div class="tradingSignals-page">
        <section class="blockElement space">
            <div class="container">
                <div class="row g-0 align-items-center">
                    <div class="col-12 col-xl-6 line-60 mob-center">
                        <h1 class="extrabold mb-md-3 titleArrow">{{$t('tradingSignals.signalsText1')}}</h1>
                        <h5 class="mb-3 mb-md-4">{{$t('tradingSignals.signalsText2')}}</h5>
                        <div class="singleButton">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5 shadow">{{$t('tradingSignals.signalsText3')}}</router-link>
                            <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn px-md-5 shadow">{{$t('tradingSignals.signalsText3')}}</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 d-none d-xl-block">
                        <div class="meetImg">
                            <v-lazy-image width="500" height="514" class="d-block ms-auto" src="/assets/images/funded/portrait-smiling.png" :alt="$t('tradingSignals.signalsText4')" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
     
        <section class="blockElement space bg-white howWork">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-11 text-center">
                        <h2 class="mb-3 semibold f-40">{{$t('tradingSignals.signalsText5')}}</h2>
                        <p>{{$t('tradingSignals.signalsText6')}}</p>
                        <div class="row customspace">
                            <div class="col-12 col-lg-4">
                                <div class="stageWork text-center columnDir">
                                    <div class="counter">
                                        <span>1</span>
                                    </div>
                                    <p>{{$t('tradingSignals.signalsText7')}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="stageWork text-center columnDir snd">
                                    <div class="counter">
                                        <span>2</span>
                                    </div>
                                    <p>{{$t('tradingSignals.signalsText8')}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-0">
                                <div class="stageWork text-center">
                                    <div class="counter">
                                        <span>3</span>
                                    </div>
                                    <p>{{$t('tradingSignals.signalsText9')}}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space makesZuluTrade">
            <div class="container">
                <div class="row align-items-center mobDirection">
                    <div class="col-12 col-lg-5">
                        <h2 class="mb-3 semibold f-40">{{$t('tradingSignals.signalsText10')}}</h2>
                        <p>{{$t('tradingSignals.signalsText11')}}</p>
                        <!-- <div class="mt-md-5 mt-3">
                            <a class="button fillBtn zulu_btn me-md-4 px-md-5 shadow" href="javascript:void(0)">Get Funded</a>
                        </div> -->
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="meetImg">
                            <v-lazy-image width="450" height="400" class="d-block mx-auto" src="/assets/images/funded/group50.png" :alt="$t('tradingSignals.signalsText12')" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white makesZuluTrade">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-7">
                        <div class="meetImg">
                            <v-lazy-image width="557" height="400" class="br-12 d-block mx-auto" src="/assets/images/funded/planning-future.jpg" :alt="$t('tradingSignals.signalsText13')" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <h2 class="mb-3 semibold f-40">{{$t('tradingSignals.signalsText13')}}</h2>
                        <p>{{$t('tradingSignals.signalsText14')}}</p>
                        <!-- <div class="mt-md-5 mt-3">
                            <a class="button fillBtn zulu_btn me-md-4 px-md-5 shadow" href="javascript:void(0)">Get Funded</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space makesZuluTrade">
            <div class="container">
                <div class="row align-items-center mobDirection">
                    <div class="col-12 col-lg-5">
                        <h2 class="mb-3 semibold f-40">{{$t('tradingSignals.signalsText15')}}</h2>
                        <p>{{$t('tradingSignals.signalsText16')}}</p>
                        <!-- <div class="mt-md-5 mt-3">
                            <a class="button fillBtn zulu_btn me-md-4 px-md-5 shadow" href="javascript:void(0)">Get Funded</a>
                        </div> -->
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="meetImg">
                            <v-lazy-image width="600" height="395" class="br-12 d-block mx-auto" src="/assets/images/funded/businesspeople.jpg" :alt="$t('tradingSignals.signalsText15')" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white howWork">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-11 text-center">
                        <h2 class="mb-3 semibold f-40">{{$t('tradingSignals.signalsText17')}}</h2>
                        <p>{{$t('tradingSignals.signalsText18')}}</p>
                        <div class="row py-5">
                            <div class="col-12 col-md-6 inspace mb-5 mb-lg-0">
                                <div class="stageWork mw-100 text-center card-body">
                                    <div class="counter small">
                                        <span>1</span>
                                    </div>
                                    <p>{{$t('tradingSignals.signalsText19')}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="stageWork mw-100 text-center card-body">
                                    <div class="counter small">
                                        <span>2</span>
                                    </div>
                                    <p>{{$t('tradingSignals.signalsText20')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-0 text-center">
                                <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('tradingSignals.signalsText21')}}</router-link>
                                <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('tradingSignals.signalsText21')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                         <h2 class="mb-md-4 pb-4 text-center">{{$t('about.text253')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingOne">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('tradingSignals.signalsText22')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('tradingSignals.signalsText23')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingFour">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    {{$t('tradingSignals.signalsText24')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText25')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingfive">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('tradingSignals.signalsText26')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText27')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingsix">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                    {{$t('tradingSignals.signalsText28')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText29')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingSeven">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    {{$t('tradingSignals.signalsText30')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText31')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingEight">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    {{$t('tradingSignals.signalsText32')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText33')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingNine">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                    {{$t('tradingSignals.signalsText34')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText35')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingTen">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                    {{$t('tradingSignals.signalsText36')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText37')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingEleven">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                    {{$t('tradingSignals.signalsText38')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText39')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingTwelve">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                    {{$t('tradingSignals.signalsText40')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('tradingSignals.signalsText41')}}</div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
            const store = myStore();
            return { store };
        },
    data() {
        return {
          
        };
    },
}
</script>